import React, {useEffect} from 'react';
import {boolean} from 'yup';
import {Box} from '@mui/material';

const Ad = ({show}) => {
    useEffect(() => {
        if (show) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
            }
        }
    }, [show]);
    return show && (<Box mt={3}>
        <ins className='adsbygoogle'
             style={{display: 'block', textAlign: 'center'}}
             data-ad-client='ca-pub-1532998084673160'
             data-ad-slot='3842105762'
             data-ad-format='auto'
             data-full-width-responsive='true'
        />
    </Box>)
}
Ad.prototype = {
    show: boolean
}
export default React.memo(Ad)