// material-ui
import {Box, Container, CssBaseline, Grid} from '@mui/material'
import {Outlet} from "react-router-dom"
import Loadable from '../../ui-component/Loadable'
import {lazy} from 'react'
import Ad from "../../ui-component/Ad";

const Sidebar = Loadable(lazy(() => import('./Sidebar')))
const Header = Loadable(lazy(() => import('./Header')))
const Footer = Loadable(lazy(() => import('./Footer')));
const Alternative = Loadable(lazy(() => import('./Alternative')));
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    return (
        <Box>
            <CssBaseline/>
            <Header/>
            <Alternative/>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Sidebar/>
                    <Grid  item xs md={6}>
                        <Outlet/>
                    </Grid>
                    <Sidebar />
                </Grid>
            </Container>
            <Footer/>
        </Box>
    );
};

export default MainLayout;