export const FETCH_GAMES = 'FETCH_GAMES';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const FETCH_GAMES_ERROR = 'FETCH_GAMES_ERROR';
export const FETCH_GAME = 'FETCH_GAME';
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS';
export const FETCH_GAME_ERROR = 'FETCH_GAME_ERROR';
export const DESTROY_GAME = 'DESTROY_GAME';
export const FETCH_STREAM = 'FETCH_STREAM';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';
export const FETCH_CONFIG = {type: 'FETCH_CONFIG'};