import * as actionTypes from './types';

export const initialState = {
    games: [],
    games_state: "pre",
    games_error: null,
    game: {},
    game_state: "pre",
    game_error: null,
    stream: {},
    stream_state: "pre",
    stream_error: null,
    error: {}
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const dataReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_GAMES_SUCCESS:
            return {
                ...state,
                games: action.payload,
                games_state: "success"
            }
        case actionTypes.FETCH_GAMES_ERROR:
            return {
                ...state,
                games_error: action.payload,
                games_state: "error"
            }
        case actionTypes.FETCH_GAME_SUCCESS:
            return {
                ...state,
                game: action.payload,
                game_state: "success"
            }
        case actionTypes.FETCH_GAME_ERROR:
            return {
                ...state,
                game_error: action.payload,
                game_state: "error"
            }
        case actionTypes.DESTROY_GAME:
            return {
                ...state,
                game: {},
                game_state: "pre",
                game_error: null,
                stream: {},
                stream_state: "pre",
                stream_error: null,
            }
        default:
            return state;
    }
};

export default dataReducer;