import * as actionTypes from './types';
import { store } from "./index";
import {fetchConfig} from "./actions";

export const initialState = {
    data: {},
    state: "pre"
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const configReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_CONFIG.type:
            store.dispatch(fetchConfig())
            return state
        case actionTypes.FETCH_CONFIG_SUCCESS:
            return {
                ...state,
                data: action.payload,
                state: "success"
            }
        case actionTypes.FETCH_CONFIG_ERROR:
            return {
                ...state,
                state: "error"
            }
        default:
            return state
    }
};

export default configReducer;