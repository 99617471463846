import PropTypes from 'prop-types';
// material
import {CssBaseline} from '@mui/material';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';


const main = {
    shape: {
        borderRadius: 15
    },
    typography: {
        body1: {
            fontSize: '0.9rem',
        },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Ubuntu',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
}
export const light = {
    palette: {
        type: 'light',
        background: {
            default: '#edeef0',
            paper: '#fff'
        }
    },
    ...main,
}
export const dark = {
    palette: {
        mode: 'dark'
    },
    ...main,
}
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
    children: PropTypes.node
};

export default function ThemeConfig({children}) {

    const theme = createTheme(dark);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}